import { Action } from 'redux';
import { ThunkAction } from 'redux-thunk';

import { AppState } from 'store';
import { DataCall } from 'utils/dataLayer';
import { IMessage, SEND_MESSAGE, DELETE_MESSAGE, FETCH_INBOX } from './types';
import {
  API_FETCH_INBOX,
  API_ADD_INBOX,
  API_DELETE_INBOX,
} from 'constants/apiRoutes';

export const fetchMessages = (): ThunkAction<
  void,
  AppState,
  null,
  Action<string>
> => async (dispatch) => {
  const payload = await DataCall({
    method: API_FETCH_INBOX.method,
    path: API_FETCH_INBOX.path(),
  });
  dispatch({
    type: FETCH_INBOX,
    payload,
  });
};

export const sendMessage = (
  message: string,
): ThunkAction<void, AppState, null, Action<string>> => async (dispatch) => {
  const body: IMessage = {
    message,
  };
  const payload = await DataCall({
    method: API_ADD_INBOX.method,
    path: API_ADD_INBOX.path(),
    body,
  });

  dispatch({
    type: SEND_MESSAGE,
    payload,
  });
};

export const deleteMessage = (
  id: string,
): ThunkAction<void, AppState, null, Action<string>> => async (dispatch) => {
  await DataCall({
    method: API_DELETE_INBOX.method,
    path: API_DELETE_INBOX.path(id),
    expectedResponseType: 'text',
  });

  dispatch({
    type: DELETE_MESSAGE,
    meta: {
      id,
    },
  });
};
