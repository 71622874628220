import { ACCESS_TOKEN } from 'constants/constants';
import { API_URL } from 'constants/routes';

export interface IDataCallParams {
  method?: 'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE';
  path: string | ((params: { [key: string]: string }) => string);
  urlParams?: {
    [key: string]: string | number | boolean;
  };
  queryString?: string;
  expectedResponseType?: 'json' | 'blob' | 'arrayBuffer' | 'formData' | 'text';
  body?: object;
  headers?: {
    [key: string]: string | number | boolean;
  };
}

const DataCall = async ({
  body,
  expectedResponseType = 'json',
  headers = {},
  method = 'GET',
  path,
  queryString,
  urlParams,
}: IDataCallParams) => {
  let requestURL = `${API_URL}${path}`;

  /**
   * Inject the url params in the request url
   */
  if (urlParams || queryString) {
    requestURL += '?';
    requestURL += queryString;
    requestURL += Object.entries(urlParams || [])
      .map((entry) => `${entry[0]}=${entry[1]}`)
      .join('&');
  }

  /**
   * Build the headers
   */
  const requestHeaders = new Headers();
  const accessToken: string = localStorage.getItem(ACCESS_TOKEN) as string;
  requestHeaders.append('authorization', `Bearer ${accessToken}`);

  for (const header of Object.entries(headers)) {
    requestHeaders.append(header[0], String(header[1]));
  }

  /**
   * Build the body
   */
  let requestBody = null;
  if (body) {
    if (body instanceof FormData) {
      requestBody = body;
    } else if (typeof body === 'object') {
      requestBody = JSON.stringify(body);
      requestHeaders.append('Content-Type', 'application/json');
    }
  }

  /**
   * Make the request
   */
  const params: RequestInit = {
    method,
    headers: requestHeaders,
    body: requestBody,
  };
  const request = await fetch(requestURL, params);

  if (request.status === 401) {
    alert('You need to be logged in to access this resource.');
    throw Error('401 Unauthorized');
  }

  if (request.status === 404) {
    throw Error(`404 Route not found: ${request.url}`);
  }

  if (request.status !== 200 && request.status !== 201) {
    const textError = await request.text();
    let error;
    try {
      error = JSON.parse(textError);
    } catch {
      error = textError;
    }
    throw Error(`${error.code || 500} ${error.message || String(error)}`);
  }

  switch (expectedResponseType) {
    case 'json':
      return await request.json();
    case 'blob':
      return await request.blob();
    case 'arrayBuffer':
      return await request.arrayBuffer();
    case 'formData':
      return await request.formData();
    case 'text':
      return await request.text();
    default:
      return await request.json();
  }
};

export { DataCall };
