import React from 'react';
import 'styles/sass/app.scss';
import { Route, Switch, Router, Redirect } from 'react-router-dom';

import history from 'utils/history';
import PrivateRoute from 'PrivateRoute';

import Home from 'components/Home';
import Admin from 'components/Admin';
import Loader from 'components/common/Loader';
import { useAuth0 } from 'auth0';
import { APP_ROUTE_PATH } from 'constants/routes';

function App() {
  const { loading } = useAuth0();
  if (loading) return <Loader />;

  return (
    <Router history={history}>
      <Switch>
        <Route path="/" component={Home} exact />
        <PrivateRoute path={APP_ROUTE_PATH} component={Admin} />
        <Route render={() => <Redirect to="/" />} />
      </Switch>
    </Router>
  );
}

export default App;
