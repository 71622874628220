interface IAPIRoute {
  method: 'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE';
  path: (
    param1?: string,
    param2?: string,
    param3?: string,
    param4?: string,
  ) => string;
}

export const API_FETCH_PROSPECTS: IAPIRoute = {
  method: 'GET',
  path: () => '/prospect',
};

export const API_ADD_PROSPECT: IAPIRoute = {
  method: 'POST',
  path: () => '/prospect',
};

export const API_DELETE_PROSPECT: IAPIRoute = {
  method: 'DELETE',
  path: (param1) => `/prospect/${param1}`,
};

export const API_FETCH_CAMPAIGNS: IAPIRoute = {
  method: 'GET',
  path: () => '/campaign',
};

export const API_CREATE_CAMPAIGN: IAPIRoute = {
  method: 'POST',
  path: () => '/campaign',
};

export const API_DELETE_CAMPAIGN: IAPIRoute = {
  method: 'DELETE',
  path: (param1) => `/campaign/${param1}`,
};

export const API_FETCH_INBOX: IAPIRoute = {
  method: 'GET',
  path: () => '/inbox',
};

export const API_ADD_INBOX: IAPIRoute = {
  method: 'POST',
  path: () => '/inbox',
};

export const API_DELETE_INBOX: IAPIRoute = {
  method: 'DELETE',
  path: (param1) => `/inbox/${param1}`,
};
