import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import configureStore from './store';
import { MuiThemeProvider } from '@material-ui/core';

import App from 'components/App';
import history from 'utils/history';
import { Auth0Provider } from 'auth0';
import Theme from 'Theme';
import config from 'config';

import * as serviceWorker from './serviceWorker';

const onAuthRedirectCallback = (redirectResult?: RedirectLoginResult) => {
  console.log(
    'auth0 onRedirectCallback called with redirectState %o',
    redirectResult,
  );

  // Clears auth0 query string parameters from url
  const targetUrl =
    redirectResult &&
    redirectResult.appState &&
    redirectResult.appState.targetUrl
      ? redirectResult.appState.targetUrl
      : window.location.pathname;

  history.push(targetUrl);
};

const store = configureStore();

const Root = () => (
  <Auth0Provider
    domain={config.domain}
    client_id={config.clientId}
    audience={config.audience}
    redirect_uri={window.location.origin}
    onRedirectCallback={onAuthRedirectCallback}>
    <MuiThemeProvider theme={Theme}>
      <Provider store={store}>
        <App />
      </Provider>
    </MuiThemeProvider>
  </Auth0Provider>
);

ReactDOM.render(<Root />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
