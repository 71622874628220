import React, { useState, useEffect, useCallback } from 'react';
import { Link } from 'react-router-dom';
import clsx from 'clsx';
import { debounce } from 'lodash';
import { Icon, Fab, IconButton } from '@material-ui/core';
import ScrollTo from '../common/ScrollTo';

import Logo from 'components/common/Logo';
import { useAuth0 } from 'auth0';
import { CAMPAIGNS_ROUTE_PATH } from 'constants/routes';

let handleScrollRef: () => void = () => null;

const TopBar: React.FC = () => {
  const { isAuthenticated } = useAuth0();
  const [top, setTop] = useState(true);
  const [closed, setClosed] = useState(false);

  const handleScroll = useCallback(() => {
    return debounce(() => {
      if (window) {
        const isTop = window.scrollY < 100;
        if (isTop !== top) {
          setTop(isTop);
        }
      }
    }, 20);
  }, [top]);

  useEffect(() => {
    if (window) {
      handleScrollRef = handleScroll();
      window.addEventListener('scroll', handleScrollRef);
    }
    return () => {
      if (window) {
        window.removeEventListener('scroll', handleScrollRef);
      }
    };
  }, [handleScroll]);

  const close = () => {
    setClosed(true);
  };

  const toggleIcon = closed ? 'menu' : 'close';
  return (
    <section
      className={clsx('header', {
        'header-fixed': !top,
        closed: closed,
      })}>
      <div className="container header-container">
        <div className="brand">
          <Logo />
        </div>
        <ul className="navigation">
          <li>
            <ScrollTo to="intro3" onScroll={close}>
              Home
            </ScrollTo>
          </li>

          <li>
            <ScrollTo to="service3" onScroll={close}>
              Service
            </ScrollTo>
          </li>
          <li>
            <ScrollTo to="service5" onScroll={close}>
              Features
            </ScrollTo>
          </li>
          <li>
            <ScrollTo to="pricing1" onScroll={close}>
              Pricing
            </ScrollTo>
          </li>
          <li>
            <ScrollTo to="contact1" onScroll={close}>
              Contact
            </ScrollTo>
          </li>
        </ul>
        <div className="m-auto" />
        <Link to={CAMPAIGNS_ROUTE_PATH}>
          <Fab variant="extended" size="medium" color="secondary">
            <Icon>{isAuthenticated ? 'account_circle' : 'lock_open'}</Icon>
            {isAuthenticated ? 'My account' : 'Log in'}
          </Fab>
        </Link>
        <IconButton
          className="header__toggle"
          onClick={() => {
            setClosed(!closed);
          }}>
          <Icon>{toggleIcon}</Icon>
        </IconButton>
      </div>
    </section>
  );
};

export default TopBar;
