import React, { useState } from 'react';
import { useRouteMatch, useLocation, useHistory } from 'react-router-dom';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import {
  AppBar,
  Toolbar,
  IconButton,
  MenuItem,
  Menu,
  Button,
  Icon,
  Drawer,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
} from '@material-ui/core';

import { useAuth0 } from 'auth0';
import Logo from 'components/common/Logo';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    menuButton: {
      marginRight: theme.spacing(2),
      display: 'flex',
      [theme.breakpoints.up('md')]: {
        display: 'none',
      },
    },
    appBar: {
      background: theme.palette.primary.light,
    },
    logo: {
      flexGrow: 1,
    },
    img: {
      width: 100,
      margin: 'auto',
    },
    menu: {
      display: 'none',
      flexGrow: 4,
      [theme.breakpoints.up('md')]: {
        display: 'flex',
      },
      '& > *': {
        margin: theme.spacing(1),
        textTransform: 'capitalize',
      },
    },
    list: {
      width: 200,
    },
    listItem: {
      textTransform: 'capitalize',
    },
  }),
);

interface IMenuItem {
  label: string;
  icon: string;
}

const menuItems: Array<IMenuItem> = [
  {
    label: 'campaigns',
    icon: 'mail',
  },
  {
    label: 'prospects',
    icon: 'people_icon',
  },
  {
    label: 'inbox',
    icon: 'inbox',
  },
];

interface IMobileMenuProps {
  toggleDrawer: (
    open: boolean,
  ) =>
    | ((event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void)
    | ((event: React.KeyboardEvent<HTMLDivElement>) => void);
}

const MobileMenu = ({ toggleDrawer }: IMobileMenuProps) => {
  const classes = useStyles();
  const { path } = useRouteMatch();
  const { pathname } = useLocation();
  const { push } = useHistory();
  return (
    <div
      className={classes.list}
      role="presentation"
      onClick={
        toggleDrawer(false) as (
          event: React.MouseEvent<HTMLDivElement, MouseEvent>,
        ) => void
      }
      onKeyDown={
        toggleDrawer(false) as (
          event: React.KeyboardEvent<HTMLDivElement>,
        ) => void
      }>
      <List>
        <ListItem>
          <Logo className={classes.img} />
        </ListItem>
        {menuItems.map(({ label, icon }) => (
          <ListItem
            onClick={() => push(`${path}/${label}`)}
            component={Button}
            key={label}
            className={classes.listItem}
            selected={pathname === `${path}/${label}`}>
            <ListItemIcon>
              <Icon>{icon}</Icon>
            </ListItemIcon>
            <ListItemText primary={label} />
          </ListItem>
        ))}
      </List>
    </div>
  );
};

const AdminTopBar = () => {
  const { path } = useRouteMatch();
  const { pathname } = useLocation();
  const { push } = useHistory();
  const classes = useStyles();
  const { logout } = useAuth0();
  const [openDrawer, setOpenDrawer] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const toggleDrawer = (open: boolean) => (
    event: React.KeyboardEvent | React.MouseEvent,
  ) => {
    if (
      event.type === 'keydown' &&
      ((event as React.KeyboardEvent).key === 'Tab' ||
        (event as React.KeyboardEvent).key === 'Shift')
    ) {
      return;
    }

    setOpenDrawer(open);
  };

  return (
    <>
      <div className={classes.root}>
        <AppBar position="static" className={classes.appBar}>
          <Toolbar>
            <IconButton
              edge="start"
              className={classes.menuButton}
              color="inherit"
              aria-label="menu"
              onClick={toggleDrawer(true)}>
              <Icon>menu</Icon>
            </IconButton>
            <div className={classes.logo}>
              <Logo className={classes.img} />
            </div>
            <div className={classes.menu}>
              {menuItems.map(({ label, icon }) => (
                <Button
                  key={icon}
                  onClick={() => push(`${path}/${label}`)}
                  color={
                    pathname === `${path}/${label}` ? 'secondary' : 'primary'
                  }
                  startIcon={<Icon>{icon}</Icon>}>
                  {label}
                </Button>
              ))}
            </div>
            <div>
              <IconButton
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleMenu}
                color="inherit">
                <Icon>account_circle</Icon>
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={open}
                onClose={handleClose}>
                <MenuItem
                  onClick={() => {
                    handleClose();
                    push(`${path}/profile`);
                  }}>
                  <Icon>account_box</Icon> My account
                </MenuItem>
                <MenuItem
                  onClick={() => logout({ returnTo: window.location.origin })}>
                  <Icon>exit_to_app</Icon> Logout
                </MenuItem>
              </Menu>
            </div>
          </Toolbar>
        </AppBar>
      </div>
      <Drawer anchor="left" open={openDrawer} onClose={toggleDrawer(false)}>
        <MobileMenu toggleDrawer={toggleDrawer} />
      </Drawer>
    </>
  );
};

export default AdminTopBar;
