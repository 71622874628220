import React from 'react';

interface IProps {
  className?: string;
}

const Logo = ({ className }: IProps) => {
  return (
    <img
      src="/assets/images/logo.png"
      alt="oleads logo"
      className={className}
    />
  );
};

export default Logo;
