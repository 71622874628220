// Describing the shape of the chat's slice of state
export interface IMessage {
  id?: string;
  message: string;
  createdAt?: Date;
}

export interface IInboxState {
  messages: IMessage[];
}

// Describing the different ACTION NAMES available
export const FETCH_INBOX = 'FETCH_INBOX';
export const SEND_MESSAGE = 'SEND_MESSAGE';
export const DELETE_MESSAGE = 'DELETE_MESSAGE';

interface IFetchInboxAction {
  type: typeof FETCH_INBOX;
  payload: Array<IMessage>;
}

interface ISendMessageAction {
  type: typeof SEND_MESSAGE;
  payload: IMessage;
}

interface IDeleteMessageAction {
  type: typeof DELETE_MESSAGE;
  meta: {
    id: string;
  };
}

export type ChatActionTypes =
  | IFetchInboxAction
  | ISendMessageAction
  | IDeleteMessageAction;
