import { Action } from 'redux';
import { ThunkAction } from 'redux-thunk';

import { AppState } from 'store';
import { DataCall } from 'utils/dataLayer';
import {
  ICampaign,
  CREATE_CAMPAIGN,
  DELETE_CAMPAIGN,
  FETCH_CAMPAIGNS,
} from './types';
import {
  API_FETCH_CAMPAIGNS,
  API_CREATE_CAMPAIGN,
  API_DELETE_CAMPAIGN,
} from 'constants/apiRoutes';

export const fetchCampaigns = (): ThunkAction<
  void,
  AppState,
  null,
  Action<string>
> => async (dispatch) => {
  const payload = await DataCall({
    method: API_FETCH_CAMPAIGNS.method,
    path: API_FETCH_CAMPAIGNS.path(),
  });
  dispatch({
    type: FETCH_CAMPAIGNS,
    payload,
  });
};

export const createCampaign = (
  name: string,
): ThunkAction<void, AppState, null, Action<string>> => async (dispatch) => {
  const body: ICampaign = {
    name,
  };

  const payload = await DataCall({
    method: API_CREATE_CAMPAIGN.method,
    path: API_CREATE_CAMPAIGN.path(),
    body,
  });

  dispatch({
    type: CREATE_CAMPAIGN,
    payload,
  });
};

export const deleteCampaign = (
  id: string,
): ThunkAction<void, AppState, null, Action<string>> => async (dispatch) => {
  await DataCall({
    method: API_DELETE_CAMPAIGN.method,
    path: API_DELETE_CAMPAIGN.path(id),
    expectedResponseType: 'text',
  });

  dispatch({
    type: DELETE_CAMPAIGN,
    meta: {
      id,
    },
  });
};
