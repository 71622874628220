import { createStore, combineReducers, applyMiddleware } from 'redux';
import thunkMiddleware from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';

import { campaignsReducer } from './campaigns/reducers';
import { prospectsReducer } from './prospects/reducers';
import { inboxReducer } from './inbox/reducers';

const rootReducer = combineReducers({
  campaigns: campaignsReducer,
  prospects: prospectsReducer,
  inbox: inboxReducer,
});

export type AppState = ReturnType<typeof rootReducer>;

export default function configureStore() {
  const middlewares = [thunkMiddleware];
  const middleWareEnhancer = applyMiddleware(...middlewares);

  const store = createStore(
    rootReducer,
    composeWithDevTools(middleWareEnhancer),
  );

  return store;
}
