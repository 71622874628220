import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import {
  Theme,
  makeStyles,
  createStyles,
  Button,
  Icon,
} from '@material-ui/core';

import { AppState } from 'store';
import {
  fetchProspects,
  addProspect,
  deleteProspect,
} from 'store/prospects/actions';
import { IProspectsState } from 'store/prospects/types';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      position: 'relative',
      marginTop: theme.spacing(2),
    },
  }),
);

interface IProps {
  prospects: IProspectsState;
  fetchProspects: any;
  addProspect: any;
  deleteProspect: any;
}

const Prospects = ({
  prospects,
  fetchProspects,
  addProspect,
  deleteProspect,
}: IProps) => {
  const classes = useStyles();
  useEffect(() => {
    fetchProspects();
  }, [fetchProspects]);
  return (
    <div className={classes.root}>
      <Button
        onClick={() =>
          addProspect(
            `firstname ${prospects.data.length + 1}`,
            `lastname ${prospects.data.length + 1}`,
            `mail${prospects.data.length + 1}@provider.com`,
          )
        }
        variant="contained">
        ADD PROSPECT
      </Button>
      {prospects.data.map(({ firstname, lastname, email, id }) => (
        <div key={id}>
          {firstname} {lastname} {email}
          <Button size="small" onClick={() => deleteProspect(id as string)}>
            <Icon fontSize="small">delete</Icon>
          </Button>
        </div>
      ))}
    </div>
  );
};

const mapStateToProps = (state: AppState) => ({
  prospects: state.prospects,
});

export default connect(mapStateToProps, {
  addProspect,
  deleteProspect,
  fetchProspects,
})(Prospects);
