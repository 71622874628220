// Describing the shape of the chat's slice of state
export interface ICampaign {
  id?: string;
  name: string;
  createdAt?: Date;
}

export interface ICampaignsState {
  data: ICampaign[];
}

// Describing the different ACTION NAMES available
export const FETCH_CAMPAIGNS = 'FETCH_CAMPAIGNS';
export const CREATE_CAMPAIGN = 'CREATE_CAMPAIGN';
export const DELETE_CAMPAIGN = 'DELETE_CAMPAIGN';

interface IFetchCampaignAction {
  type: typeof FETCH_CAMPAIGNS;
  payload: Array<ICampaign>;
}

interface ICreateCampaignAction {
  type: typeof CREATE_CAMPAIGN;
  payload: ICampaign;
}

interface IDeleteCampaignAction {
  type: typeof DELETE_CAMPAIGN;
  meta: {
    id: string;
  };
}

export type CampaignsActionTypes =
  | ICreateCampaignAction
  | IDeleteCampaignAction
  | IFetchCampaignAction;
