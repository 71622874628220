import { createMuiTheme } from '@material-ui/core/styles';
import { blueGrey, grey, red } from '@material-ui/core/colors';

const Theme = createMuiTheme({
  palette: {
    primary: blueGrey,
    secondary: grey,
    error: red,

    contrastThreshold: 3,
    // Used to shift a color's luminance by approximately
    // two indexes within its tonal palette.
    // E.g., shift from Red 500 to Red 300 or Red 700.
    tonalOffset: 0.2,
  },
});

export default Theme;
