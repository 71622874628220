export default [
  {
    img: '/assets/images/providers/google-logo.svg',
    title: 'Google',
  },
  {
    img: '/assets/images/providers/amazon-ses-logo.svg',
    title: 'aws',
  },
  {
    img: '/assets/images/providers/mailgun-logo.svg',
    title: 'mailgun',
  },
  {
    img: '/assets/images/providers/mandrill-logo.svg',
    title: 'MANDRILL',
  },
  {
    img: '/assets/images/providers/sendgrid-logo.svg',
    title: 'SendGrid',
  },
  {
    img: '/assets/images/providers/smtp.svg',
    title: 'SMTP',
  },
  {
    img: '/assets/images/providers/sparkpost-logo.svg',
    title: 'SPARKPOST',
  },
];
