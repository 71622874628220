import React, { Component } from 'react';
import { Grid, Icon, Link } from '@material-ui/core';

class Footer extends Component {
  state = {};
  render() {
    return (
      <div className="section-footer1 light-dark" id="footer1">
        <div className="container">
          <Grid container>
            <Grid item lg={5} md={5} sm={12}>
              <div className="footer1__about">
                <h4>Who we are</h4>
                <p>
                  Oleads.ai is a follow-up automation SaaS founded in 2019.
                  We&apos;re an international team of 10, speaking 4 languages.
                  Our mission is to enable all B2B companies to connect with
                  their ideal customers.
                </p>
              </div>
            </Grid>

            <Grid item lg={3} md={3} sm={12}>
              <div className="footer1__contact">
                <h4>Contact</h4>
                <div className="px-16 my-32">
                  <Icon className="footer1__contact__icon">mail</Icon>
                  <div className="pl-16">
                    <h5 className="m-0 p-0">Email</h5>
                    <p className="m-0 p-0">
                      <Link href="mailto:support@oleads.ai">
                        support@oleads.ai
                      </Link>
                    </p>
                  </div>
                </div>
                <div className="px-16 mt-32">
                  <Icon className="footer1__contact__icon">location_on</Icon>
                  <div className="pl-16">
                    <h5 className="m-0 p-0">Address</h5>
                    <p className="m-0 p-0">128 Rue la Boétie 75008 Paris</p>
                  </div>
                </div>
              </div>
            </Grid>

            <Grid item lg={4} md={4} sm={12}>
              <div className="footer1__disclaimer">
                <h4>Follow us</h4>
                <div className="px-16 mt-32 footer1__disclaimer__link">
                  <a href="#linkedin" className="px-8">
                    <img src="./assets/images/social-linkedin.png" alt="" />
                  </a>
                  <a href="#twitter" className="px-8">
                    <img src="./assets/images/social-twitter.png" alt="" />
                  </a>
                  <a href="#facebook" className="px-8">
                    <img
                      src="./assets/images/social-facebook.png"
                      alt="https://www.facebook.com/oleadsapp"
                    />
                  </a>
                </div>
              </div>
            </Grid>
          </Grid>
        </div>
      </div>
    );
  }
}

export default Footer;
