import React from 'react';
import { useAuth0 } from 'auth0';
import Loader from 'components/common/Loader';
import { makeStyles, Theme, createStyles } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      position: 'relative',
      marginTop: theme.spacing(2),
    },
  }),
);

const Profile = () => {
  const classes = useStyles();
  const { user } = useAuth0();
  if (!user) {
    return <Loader />;
  }
  return (
    <div className={classes.root}>
      {Object.entries(user).map(([key, value]) => (
        <div key={key}>
          {key}: {value}
        </div>
      ))}
    </div>
  );
};

export default Profile;
