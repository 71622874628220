import {
  ICampaignsState,
  CREATE_CAMPAIGN,
  DELETE_CAMPAIGN,
  CampaignsActionTypes,
  FETCH_CAMPAIGNS,
} from './types';

const initialState: ICampaignsState = {
  data: [],
};

export function campaignsReducer(
  state = initialState,
  action: CampaignsActionTypes,
): ICampaignsState {
  switch (action.type) {
    case FETCH_CAMPAIGNS:
      return {
        data: [...action.payload],
      };
    case CREATE_CAMPAIGN:
      return {
        data: [...state.data, action.payload],
      };
    case DELETE_CAMPAIGN:
      return {
        data: state.data.filter((campaign) => campaign.id !== action.meta.id),
      };
    default:
      return state;
  }
}
