import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import {
  Button,
  Fab,
  Icon,
  Theme,
  makeStyles,
  createStyles,
} from '@material-ui/core';

import { AppState } from 'store';
import { ICampaignsState } from 'store/campaigns/types';
import { NEW_CAMPAIGNS_ROUTE_PATH } from 'constants/routes';
import {
  fetchCampaigns,
  createCampaign,
  deleteCampaign,
} from 'store/campaigns/actions';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      position: 'relative',
      height: 'calc(100vh - 100px)',
      marginTop: theme.spacing(2),
    },
    fab: {
      position: 'absolute',
      bottom: theme.spacing(2),
      right: theme.spacing(2),
    },
  }),
);

interface IProps {
  campaigns: ICampaignsState;
  fetchCampaigns: any;
  createCampaign: any;
  deleteCampaign: any;
}

const Campaings = ({
  campaigns,
  fetchCampaigns,
  createCampaign,
  deleteCampaign,
}: IProps) => {
  const classes = useStyles();
  const { push } = useHistory();

  useEffect(() => {
    fetchCampaigns();
  }, [fetchCampaigns]);

  return (
    <div className={classes.root}>
      <Button
        onClick={() => createCampaign(`Campaign ${campaigns.data.length + 1}`)}
        variant="contained">
        CREATE CAMPAIGN
      </Button>
      {campaigns.data.map(({ id, name, createdAt }) => (
        <div key={id}>
          {name} ({new Date(createdAt as Date).toISOString()})
          <Button size="small" onClick={() => deleteCampaign(id)}>
            <Icon fontSize="small">delete</Icon>
          </Button>
        </div>
      ))}
      <Fab
        color="primary"
        aria-label="add"
        className={classes.fab}
        onClick={() => push(NEW_CAMPAIGNS_ROUTE_PATH)}>
        <Icon>add</Icon>
      </Fab>
    </div>
  );
};

const mapStateToProps = (state: AppState) => ({
  campaigns: state.campaigns,
});

export default connect(mapStateToProps, {
  fetchCampaigns,
  createCampaign,
  deleteCampaign,
})(Campaings);
