import React, { Component } from 'react';
import {
  Switch,
  FormControlLabel,
  Grid,
  Card,
  CardHeader,
  CardContent,
  Button,
  Divider,
} from '@material-ui/core';

class Pricing extends Component {
  pricingList = [
    {
      title: 'Start-up',
      subtitle: 'Basic features for smaller teams',
      price: 35,
      allowedOfferIndexList: [0, 1, 2],
    },
    {
      title: 'Team Pro',
      subtitle: 'Advanced features for bigger teams',
      price: 70,
      allowedOfferIndexList: [0, 1, 2, 3],
    },
    {
      title: 'Business',
      subtitle: 'Custom integrations for enterprise',
      price: 'Contact us for custom pricing',
      allowedOfferIndexList: [0, 1, 2, 3, 4],
    },
  ];

  offerList = [
    'Contact unlimited people per month',
    'Any IMAP integration',
    'Gmail integration',
    'Outlook integration',
    'Email throttling & human-like sending',
    'Reply, click & open tracking',
    'Advanced personalization',
    'Timezone setup',
    'Replies in your inbox',
    "Sending in prospect's timezone",
  ];

  state = {
    switchToggled: false,
    plan: 'Mo',
    off: 10,
  };

  getPriceList() {
    const { switchToggled, off } = this.state;

    if (switchToggled) {
      return [...this.pricingList].map((item) => {
        const plan = { ...item };
        let { price } = plan;

        if (typeof price === 'number') {
          price = price * 12;
          price = Math.round(price - (price * off) / 100);
        }
        plan.price = price;
        return plan;
      });
    }

    return this.pricingList;
  }

  handleSwitchChange = () => {
    let { switchToggled, plan } = this.state;
    switchToggled = !switchToggled;
    switchToggled ? (plan = 'Yr') : (plan = 'Mo');
    this.setState({ switchToggled, plan });
  };

  render() {
    return (
      <div className="section section-pricing1" id="pricing1">
        <div className="container">
          <div className="section__header">
            <h2>Choose a Plan</h2>
            <FormControlLabel
              control={
                <Switch
                  color="primary"
                  checked={this.state.switchToggled}
                  onChange={this.handleSwitchChange}
                />
              }
              label="Get up to 10% discount annually"
            />
          </div>

          <Grid container spacing={2}>
            {this.getPriceList().map((plan) => {
              const { title, subtitle, price, allowedOfferIndexList } = plan;

              return (
                <Grid item lg={4} md={4} sm={6} xs={12} key={title}>
                  <Card className="text-center card">
                    <CardHeader
                      className={
                        title === 'Starter'
                          ? 'pricing1__card-header pricing1__highlighted'
                          : 'pricing1__card-header'
                      }
                      title={title}
                      subheader={subtitle}
                    />
                    <Divider />
                    <CardContent className="pricing1__card-content">
                      <h1>
                        {typeof price == 'number'
                          ? `$ ${price} /${this.state.plan}`
                          : 'Custom pricing'}
                      </h1>

                      {this.offerList.map((offer, index) => (
                        <div
                          key={index}
                          className={
                            allowedOfferIndexList.includes(index)
                              ? ''
                              : 'text-muted'
                          }>
                          {offer}
                        </div>
                      ))}

                      <div className="text-center">
                        <Button color="secondary" variant="contained">
                          Choose
                        </Button>
                      </div>
                    </CardContent>
                  </Card>
                </Grid>
              );
            })}
          </Grid>
        </div>
      </div>
    );
  }
}

export default Pricing;
