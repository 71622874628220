import React, { Component } from 'react';
import { scrollTo } from '../../Utils';

interface IProps {
  to: string;
  onScroll: (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void;
}

class ScrollTo extends Component<IProps> {
  state = {};
  render() {
    const { to, onScroll } = this.props;
    return (
      <a
        href={`#${to}`}
        onClick={(e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
          scrollTo(to);
          if (onScroll) {
            e.preventDefault();
            onScroll(e);
          }
        }}>
        {this.props.children}
      </a>
    );
  }
}

export default ScrollTo;
