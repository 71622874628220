import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import {
  Button,
  Icon,
  makeStyles,
  Theme,
  createStyles,
} from '@material-ui/core';

import { AppState } from 'store';
import { IInboxState } from 'store/inbox/types';
import { fetchMessages, sendMessage, deleteMessage } from 'store/inbox/actions';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      position: 'relative',
      marginTop: theme.spacing(2),
    },
  }),
);

interface IProps {
  inbox: IInboxState;
  fetchMessages: any;
  sendMessage: any;
  deleteMessage: any;
}

const Inbox = ({
  inbox,
  fetchMessages,
  sendMessage,
  deleteMessage,
}: IProps) => {
  const classes = useStyles();
  useEffect(() => {
    fetchMessages();
  }, [fetchMessages]);
  return (
    <div className={classes.root}>
      <Button
        onClick={() => sendMessage(`Message ${inbox.messages.length + 1}`)}
        variant="contained">
        SEND MESSAGE
      </Button>
      {inbox.messages.map(({ id, message, createdAt }) => (
        <div key={id}>
          {message} ({new Date(createdAt as Date).toISOString()})
          <Button size="small" onClick={() => deleteMessage(id as string)}>
            <Icon fontSize="small">delete</Icon>
          </Button>
        </div>
      ))}
    </div>
  );
};

const mapStateToProps = (state: AppState) => ({
  inbox: state.inbox,
});

export default connect(mapStateToProps, {
  fetchMessages,
  sendMessage,
  deleteMessage,
})(Inbox);
