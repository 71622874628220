// Describing the shape of the chat's slice of state
export interface IProspect {
  id?: string;
  userId: string;
  firstname: string;
  lastname: string;
  company: string;
  email: string;
  sexe: 'male' | 'female' | string;
}

export interface IProspectsState {
  data: IProspect[];
  count: number;
  total: number;
  page: number;
}

// Describing the different ACTION NAMES available
export const FETCH_PROSPECTS = 'FETCH_PROSPECTS';
export const ADD_PROSPECT = 'ADD_PROSPECT';
export const DELETE_PROSPECT = 'DELETE_PROSPECT';

interface IFetchProspectsAction {
  type: typeof FETCH_PROSPECTS;
  payload: IProspectsState;
}

interface IAddProspectAction {
  type: typeof ADD_PROSPECT;
  payload: IProspect;
}

interface IDeleteProspectAction {
  type: typeof DELETE_PROSPECT;
  meta: {
    id: string;
  };
}

export type ProspectsActionTypes =
  | IAddProspectAction
  | IDeleteProspectAction
  | IFetchProspectsAction;
