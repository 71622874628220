import React from 'react';
import { Switch, Route, RouteComponentProps, Redirect } from 'react-router-dom';
import { Container } from '@material-ui/core';
import {
  Campaigns,
  AdminTopBar,
  Prospects,
  Inbox,
  Profile,
  Workflow,
} from './components';
import {
  CAMPAIGNS_ROUTE_PATH,
  NEW_CAMPAIGNS_ROUTE_PATH,
  PROSPECTS_ROUTE_PATH,
  INBOX_ROUTE_PATH,
  PROFILE_ROUTE_PATH,
} from 'constants/routes';

const Admin = ({ match: { path } }: RouteComponentProps) => {
  return (
    <>
      <AdminTopBar />
      <Container>
        <Switch>
          <Route path={CAMPAIGNS_ROUTE_PATH} component={Campaigns} exact />
          <Route path={NEW_CAMPAIGNS_ROUTE_PATH} component={Workflow} exact />
          <Route path={PROSPECTS_ROUTE_PATH} component={Prospects} exact />
          <Route path={INBOX_ROUTE_PATH} component={Inbox} exact />
          <Route path={PROFILE_ROUTE_PATH} component={Profile} exact />
          <Route render={() => <Redirect to={`${path}/campaigns`} />} />
        </Switch>
      </Container>
    </>
  );
};

export default Admin;
