import React from 'react';
import clsx from 'clsx';
import { Grid, Paper } from '@material-ui/core';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import providers from './providers';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      margin: 10,
    },
    paper: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      padding: theme.spacing(2),
      color: theme.palette.text.secondary,
      height: 65,
    },
    img: {
      maxWidth: '100%',
      maxHeight: '100%',
    },
    active: {
      border: `1px solid ${theme.palette.primary.dark}`,
    },
  }),
);

interface IProps {
  currentProvider: string | null;
  setCurrentProvider: (provider: string) => void;
}

const MailingSettings = ({ currentProvider, setCurrentProvider }: IProps) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Grid container spacing={3}>
        {providers.map(({ title, img }) => (
          <Grid item xs={6} sm={3} key={title}>
            <Paper
              className={clsx(classes.paper, {
                [classes.active]: title === currentProvider,
              })}
              onClick={() => setCurrentProvider(title)}>
              <img className={classes.img} alt={title} src={img} />
            </Paper>
          </Grid>
        ))}
      </Grid>
    </div>
  );
};

export default MailingSettings;
