/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, { useState, useEffect, useContext } from 'react';
import createAuth0Client from '@auth0/auth0-spa-js';
import Auth0Client from '@auth0/auth0-spa-js/dist/typings/Auth0Client';
import { ACCESS_TOKEN, USER } from 'constants/constants';

export interface IAuth0RedirectState {
  targetUrl?: string;
}

export interface IAuth0User extends Omit<IdToken, '__raw'> {}

interface IAuth0Context {
  user?: IAuth0User;
  isAuthenticated: boolean;
  loading: boolean;
  popupOpen: boolean;
  loginWithPopup(o?: PopupLoginOptions): Promise<void>;
  handleRedirectCallback(): Promise<void>;
  getIdTokenClaims(o?: getIdTokenClaimsOptions): Promise<IdToken>;
  loginWithRedirect(o?: RedirectLoginOptions): Promise<void>;
  getTokenSilently(o?: GetTokenSilentlyOptions): Promise<string | undefined>;
  getTokenWithPopup(o?: GetTokenWithPopupOptions): Promise<string | undefined>;
  logout(o?: LogoutOptions): void;
}

interface IAuth0ProviderOptions {
  children: React.ReactElement;
  onRedirectCallback(result: RedirectLoginResult): void;
}

const DEFAULT_REDIRECT_CALLBACK = () => {
  console.log('gffdlgkjfdg');
  window.history.replaceState({}, document.title, window.location.pathname);
};

interface IUser {
  ['https://api.oleads.ai/roles']: Array<string>;
  given_name: string | null;
  family_name: string | null;
  nickname: string;
  name: string;
  picture: string;
  local: string;
  updated_at: string;
  email: string;
  email_verified: boolean;
  sub: string;
}

export const getUser = (): IUser => {
  return JSON.parse(localStorage.getItem(USER) as string) as IUser;
};

export const Auth0Context = React.createContext<IAuth0Context | null>(null);
export const useAuth0 = () => useContext(Auth0Context)!;

export const Auth0Provider = ({
  children,
  onRedirectCallback = DEFAULT_REDIRECT_CALLBACK,
  ...initOptions
}: IAuth0ProviderOptions & Auth0ClientOptions) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [user, setUser] = useState<IAuth0User>();
  const [auth0Client, setAuth0Client] = useState<Auth0Client>();
  const [loading, setLoading] = useState(true);
  const [popupOpen, setPopupOpen] = useState(false);

  useEffect(() => {
    const initAuth0 = async () => {
      const auth0FromHook = await createAuth0Client(initOptions);
      setAuth0Client(auth0FromHook);

      if (
        window.location.search.includes('code=') &&
        window.location.search.includes('state=')
      ) {
        const {
          appState,
        }: RedirectLoginResult = await auth0FromHook.handleRedirectCallback();

        onRedirectCallback(appState);
      }

      const isAuthenticated = await auth0FromHook.isAuthenticated();

      setIsAuthenticated(isAuthenticated);

      if (isAuthenticated) {
        const token = await auth0FromHook.getTokenSilently();
        localStorage.setItem(ACCESS_TOKEN, token);
        const user = await auth0FromHook.getUser();
        setUser(user);
        localStorage.setItem(USER, JSON.stringify(user));
      }

      setLoading(false);
    };

    initAuth0();
    // eslint-disable-next-line
  }, []);

  const loginWithPopup = async (options?: PopupLoginOptions) => {
    setPopupOpen(true);

    try {
      await auth0Client!.loginWithPopup(options);
    } catch (error) {
      console.error(error);
    } finally {
      setPopupOpen(false);
    }

    const user = await auth0Client!.getUser();
    setUser(user);
    setIsAuthenticated(true);
  };

  const handleRedirectCallback = async () => {
    setLoading(true);

    await auth0Client!.handleRedirectCallback();
    const user = await auth0Client!.getUser();

    setLoading(false);
    setIsAuthenticated(true);
    setUser(user);
  };

  const loginWithRedirect = (options?: RedirectLoginOptions) =>
    auth0Client!.loginWithRedirect(options);

  const getTokenSilently = (options?: GetTokenSilentlyOptions) =>
    auth0Client!.getTokenSilently(options);

  const logout = (options?: LogoutOptions) => {
    localStorage.removeItem(ACCESS_TOKEN);
    localStorage.removeItem(USER);
    auth0Client!.logout(options);
  };

  const getIdTokenClaims = (options?: getIdTokenClaimsOptions) =>
    auth0Client!.getIdTokenClaims(options);

  const getTokenWithPopup = (options?: GetTokenWithPopupOptions) =>
    auth0Client!.getTokenWithPopup(options);

  return (
    <Auth0Context.Provider
      value={{
        user,
        isAuthenticated,
        loading,
        popupOpen,
        loginWithPopup,
        loginWithRedirect,
        logout,
        getTokenSilently,
        handleRedirectCallback,
        getIdTokenClaims,
        getTokenWithPopup,
      }}>
      {children}
    </Auth0Context.Provider>
  );
};
