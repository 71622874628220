import React, { Component } from 'react';
import 'styles/sass/app.scss';
import { scrollTo } from 'Utils';
import Service3 from './sections/Services3';
import Service4 from './sections/Services4';
import Service5 from './sections/Services5';
import Service6 from './sections/Services6';
import Service7 from './sections/Services7';
import Testimonial from './sections/Testimonial';
import Intro from './sections/Intro';
import TopBar from './sections/TopBar';
import CallToAction from './sections/CallToAction';
import Pricing from './sections/Pricing';
import Footer from './sections/Footer';
import Contact1 from './sections/Contact';

class Home extends Component {
  state = {};
  componentWillUnmount() {
    scrollTo('root');
  }

  render() {
    return (
      <div className="landing">
        <TopBar />
        <Intro />
        <Service3 />
        <Service4 />
        <Service5 />
        <Service6 />
        <Service7 />
        <Testimonial />
        <CallToAction />
        <Pricing />
        <Contact1 />
        <Footer />
      </div>
    );
  }
}

export default Home;
