import {
  IInboxState,
  SEND_MESSAGE,
  DELETE_MESSAGE,
  ChatActionTypes,
  FETCH_INBOX,
} from './types';

const initialState: IInboxState = {
  messages: [],
};

export function inboxReducer(
  state = initialState,
  action: ChatActionTypes,
): IInboxState {
  switch (action.type) {
    case FETCH_INBOX:
      return {
        messages: [...action.payload],
      };
    case SEND_MESSAGE:
      return {
        messages: [...state.messages, action.payload],
      };
    case DELETE_MESSAGE:
      return {
        messages: state.messages.filter(
          (message) => message.id !== action.meta.id,
        ),
      };
    default:
      return state;
  }
}
