import { Action } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { RequestQueryBuilder } from '@nestjsx/crud-request';

import { AppState } from 'store';
import { DataCall } from 'utils/dataLayer';
import {
  IProspect,
  ADD_PROSPECT,
  DELETE_PROSPECT,
  FETCH_PROSPECTS,
} from './types';
import {
  API_ADD_PROSPECT,
  API_FETCH_PROSPECTS,
  API_DELETE_PROSPECT,
} from 'constants/apiRoutes';
import { getUser } from 'auth0';

export const fetchProspects = (): ThunkAction<
  void,
  AppState,
  null,
  Action<string>
> => async (dispatch) => {
  const queryString = RequestQueryBuilder.create({
    search: { userId: getUser().sub },
    page: 1,
    limit: 20,
  }).query();
  const payload = await DataCall({
    method: API_FETCH_PROSPECTS.method,
    path: API_FETCH_PROSPECTS.path(),
    queryString,
  });
  dispatch({
    type: FETCH_PROSPECTS,
    payload,
  });
};

export const addProspect = (
  firstname: string,
  lastname: string,
  email: string,
): ThunkAction<void, AppState, null, Action<string>> => async (dispatch) => {
  const body: IProspect = {
    userId: getUser().sub,
    firstname,
    lastname,
    company: '',
    email,
    sexe: '',
  };
  const payload = await DataCall({
    method: API_ADD_PROSPECT.method,
    path: API_ADD_PROSPECT.path(),
    body,
  });

  dispatch({
    type: ADD_PROSPECT,
    payload,
  });
};

export const deleteProspect = (
  id: string,
): ThunkAction<void, AppState, null, Action<string>> => async (dispatch) => {
  await DataCall({
    method: API_DELETE_PROSPECT.method,
    path: API_DELETE_PROSPECT.path(id),
    expectedResponseType: 'text',
  });

  dispatch({
    type: DELETE_PROSPECT,
    meta: {
      id,
    },
  });
};
