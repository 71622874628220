import React, { useEffect } from 'react';
import { Route, RouteProps } from 'react-router-dom';
import { useAuth0 } from 'auth0';

const PrivateRoute = ({ component: Component, path, ...rest }: RouteProps) => {
  const { loading, isAuthenticated, loginWithRedirect } = useAuth0();

  useEffect(() => {
    if (loading || isAuthenticated) {
      return;
    }
    const fn = async () => {
      await loginWithRedirect({
        appState: { targetUrl: path },
      });
    };
    fn();
  }, [loading, isAuthenticated, loginWithRedirect, path]);

  if (isAuthenticated) {
    return <Route path={path} component={Component} {...rest} />;
  }

  return <Route path={path} render={() => null} {...rest} />;
};

export default PrivateRoute;
