import {
  IProspectsState,
  FETCH_PROSPECTS,
  ADD_PROSPECT,
  DELETE_PROSPECT,
  ProspectsActionTypes,
} from './types';

const initialState: IProspectsState = {
  data: [],
  count: 0,
  total: 0,
  page: 0,
};

export function prospectsReducer(
  state = initialState,
  action: ProspectsActionTypes,
): IProspectsState {
  switch (action.type) {
    case FETCH_PROSPECTS:
      return {
        ...state,
        data: [...action.payload.data],
      };
    case ADD_PROSPECT:
      return {
        ...state,
        data: [...state.data, action.payload],
      };
    case DELETE_PROSPECT:
      return {
        ...state,
        data: state.data.filter((prospect) => prospect.id !== action.meta.id),
      };
    default:
      return state;
  }
}
